@import "./common.scss";
.homeSearch {
  .ant-layout-header {
    padding-right: 0px;
  }
  .header-component {
    background: none !important;
    position: fixed;
    z-index: 1;
  }
  .search-title {
    margin-top: 1.46vw;
    font-weight: bold;
    font-family: $primaryFontFamily;
    margin-bottom: 3vw;
    font-size: 1.61vw;
  }
  .search-list {
    text-align: right;
    font-family: $primaryFontFamily;
    font-size: 1.09vw;
  }
  .searchBar {
    margin-top: 1.46vw;
    width: 25.62vw;
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-radius: 0.7vw;
      width: 25.62vw;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector:active {
      text-align: left;
      font-family: $primaryFontFamily;
      color: #d3d3d3;
    }
  }

  .headerSearch {
    .ant-select-selector {
      border-radius: 10px;
    }
  }

  .ant-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 0px !important;
    height: 100%;
    background: #fff;
    border-left: 0px !important;
    border-radius: 0px !important;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }
  .Advertisement-show-on-mobile-view {
    display: none !important;
  }
  .Advertisement-show {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .Advertisement-show-on-mobile-view {
    @media (max-width: 1023px) {
      display: block !important;
    }
  }
  .hide-post-type-mobile {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .hide-sharing-like {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .showing-sharing-like {
    @media (min-width: 1023px) {
      display: none;
    }
  }
  .sidebar-layout {
    background: #f0f2f5 !important;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0vw 0.95vw 0.87vw 0vw;
  }
  .beds-size-mobile {
    font-size: 16px !important;
    margin-left: 8px !important;
  }
  .img-fit-mobile {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px !important;
  }
  .default-image-card{
    object-fit: cover;
    width: 100%;
    height: 280px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px !important;
  }
  .question-title {
    font-size: 1.1vw;
  }
  .answer-title {
    font-size: 0.97vw;
  }

  .searchHomes-cost {
    font-size: 1.2vw;
    font-family: $primaryFontFamily;
    font-weight: 600;
    font-style: normal;
    color: $grey11;
    margin: 0.21vw 0.73vw;
  }
  .listing-heading {
    font-weight: 600;
    font-size: 1.17vw;
    color: #000;
    margin-top: 1vw;
    margin-bottom: 0.55vw;
  }
  .invitation-col {
    font-weight: 600;
    font-size: 14px;
    margin-top: 1vw;
    margin-bottom: 0.55vw;
    @media (min-width: 992px) and(max-width: 1024px) {
      font-weight: 600;
      font-size: 12px;
      margin-top: 7px;
      height: 26px;
      margin-bottom: 0vw;
    }
    @media (max-width: 767px) {
      font-weight: 600;
      // font-size: 14px;
      margin-top: 7px;
      // height: 26px;
      margin-bottom: 0vw;
      float: right;
    }
  }
  .header-down-text {
    font-weight: 600;
    font-size: 1vw;
    color: #000;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .sort-by-box {
    margin-top: 1vw;
    margin-bottom: 0.55vw;
    width: 80%;
    @media (max-width: 500px) {
      margin-top: 0vw !important;
      width: 100% !important;
    }
    @media (min-width: 501px) and (max-width: 767px) {
      margin-top: 0vw !important;
      width: 100% !important;
    }
  }
  .buyer-price-range {
    margin-left: 10px !important;
    margin-right: 10px !important;
    @media (max-width: 992px) {
      margin-left: 0px !important;
    }
  }
  .profile-image-mobile {
    padding-top: 5%;
    margin-left: 10px !important;
    @media (max-width: 992px) {
      padding-top: 0% !important;
      margin-left: 0px !important;
    }
  }
  .side-bar {
    background: #f0f2f5;
    .ant-layout-sider {
      background: #f0f2f5 !important;
    }
    .filter-input-number {
      width: 8vw !important;
    }
    .google-place-auto-search-location {
      height: auto !important;
      padding: 2% 6% 2% 6% !important;
    }

    .slider-two {
      width: 13.9vw;
      margin-top: 2vw;
      margin-left: 3.5vw;
      margin-bottom: 3vw;
    }
    .slider-three {
      margin-top: 3vw;
      margin-left: 4vw;
      width: 13.9vw;
    }
    .marginLeft {
      font-family: $primaryFontFamily;
      margin-left: 1.8vw;
    }
    .status-bar-level {
      margin-left: 0vw;
    }
    .ant-checkbox-group-item {
      margin-right: 0 !important;
      margin-top: 0.36vw !important;
      margin-left: 1.56vw !important;
      margin-bottom: 0.73vw;
      font-family: $primaryFontFamily;
    }
    .ant-checkbox + span {
      font-size: 1.1vw;
      font-family: $primaryFontFamily;
      font-weight: normal;
    }
  }
}
.searchHomes-titles {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-style: normal;
  font-size: 1.17vw;
  color: $black;

  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.47vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.6vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 2vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 3vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw !important;
    margin-bottom: 10px !important;
  }
}
.searchHomes-type {
  font-family: $primaryFontFamily;
  font-style: normal;
  letter-spacing: 0.2vw;
  color: $grey7;
  text-transform: uppercase;
  font-size: 0.87vw;
}

.searchHomes-subdata {
  text-align: left;
  font-family: $primaryFontFamily;
  font-weight: medium;
  font-style: normal;
  color: $black;
  font-size: 1.17vw;
}
.searchHomes-sellername {
  font-weight: 600;
  font-style: normal;
  font-family: $primaryFontFamily;
  color: $blueGradient;
  font-size: 1.02vw;
}
.column {
  float: left;
  text-align: center;
  border: 1px solid #fc572b;
  color: #fc572b;
}
.column1 {
  float: left;
  text-align: center;
  color: #fc572b;
}
.all {
  width: 3.3vw;
  height: 2vw;
  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 3.7vw;
    height: 2.1vw;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    width: 4.3vw;
    height: 2.7vw;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    width: 4.3vw;
    height: 2.7vw;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 5.3vw;
    height: 3.1vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    width: 7.7vw;
    height: 5.1vw;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    height: 6vw;
    width: 7.3vw;
  }

  .more {
    transform: rotate(90deg);
    padding-left: 0.73vw;
    font-size: 1.4vw;
  }
}
.left,
.middle {
  width: 3vw;
  @media (min-width: 100px) and(max-width:767px) {
    width: 8vw;
  }
}
.right {
  width: 7vw;
  height: 1.75vw;
  .message {
    font-size: 1.2vw;
    font-family: $primaryFontFamily;
    padding-bottom: 1%;
    color: #fc572b !important;
  }
}
.share {
  font-size: 1.2vw;
  @media (min-width: 1281px) and (max-width: 1680px) {
    font-size: 1.4vw;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    font-size: 1.8vw;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3.2vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw;
  }
}
.heart {
  font-size: 1.2vw;
  @media (min-width: 1281px) and (max-width: 1680px) {
    font-size: 1.4vw;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    font-size: 1.8vw;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3.2vw;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw;
  }
}
.icons {
  display: cover;
  width: 2vw;
}
.icon1 {
  @media (min-width: 992px) {
    margin-top: -1vw;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-top: -2.2vw;
  }
  @media (min-width: 425px) and (max-width: 991.98px) {
    margin-top: -4.7vw;
  }
}
.icon2 {
  display: cover;
  width: 1.3vw;
}

.no-lines:hover {
  text-decoration: none;
}

.searchType {
  .ant-input {
    border-radius: 10px;
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    opacity: 0;
  }
}

.side-first-row {
  width: 20.5vw;
  background: white;
  font-size: 1.31vw;
  .side-filter {
    font-family: $primaryFontFamily;
    font-weight: bold;
    font-size: 0.8vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  .side-Search {
    font-family: $primaryFontFamily;
    font-weight: bold;
    font-size: 0.8vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  .side-Reset {
    color: #fc572b;
    font-family: $primaryFontFamily;
    font-size: 0.8vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border: 1px solid #fc572b;
  }
}

@media (min-width: 1600px) {
  .side-filter {
    width: 80%;
  }
  .side-Reset {
    width: 80%;
  }
}
@media (min-width: 1281px) and (max-width: 1599px) {
  .side-filter {
    width: 90%;
  }
  .side-Reset {
    width: 90%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .side-filter {
    width: 85%;
  }
  .side-Reset {
    width: 85%;
  }
  .rest-all {
    margin-left: -5px;
  }
}

@media (min-width: 100px) and(max-width:499px) {
  .side-first-row {
    .side-filter {
      font-size: 3.8vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 95%;
    }

    .side-Search {
      font-size: 3.2vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 100%;
    }

    .side-Reset {
      font-size: 3.8vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 95%;
    }
  }
}
@media (min-width: 500px) and(max-width:767px) {
  .side-first-row {
    .side-filter {
      font-size: 2vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
    }

    .side-Search {
      font-size: 1.8vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 100%;
    }

    .side-Reset {
      font-size: 2vw;
      margin-top: 5vw;
      margin-bottom: 5vw;
    }
  }
}
@media (min-width: 768px) and(max-width:992px) {
  .side-first-row {
    .side-filter {
      font-size: 1.5vw;
      margin-top: 3vw;
      margin-bottom: 3vw;
    }

    .side-Search {
      font-size: 1.3vw;
      margin-top: 3vw;
      margin-bottom: 3vw;
      width: 100%;
    }

    .side-Reset {
      font-size: 1.5vw;
      margin-top: 3vw;
      margin-bottom: 3vw;
    }
  }
}
@media (min-width: 993px) and(max-width:1024px) {
  .side-first-row {
    .side-filter {
      font-size: 1vw;
      width: 100%;
    }
    .side-Search {
      font-size: 0.8vw;
      width: 100%;
    }
    .side-Reset {
      font-size: 1vw;
      width: 90%;
    }
  }
  .rest-all {
    margin-left: -10px;
  }
}

.side-filter-agent {
  color: #131419;
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins";
}
.side-Reset-agent {
  color: #db0000;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins";
  border: 1px solid #fc572b;
}

.text-agent {
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: black;
  font-weight: bold;
  font-size: 1.1vw;
  font-family: "Poppins", sans-serif;
}
.btn-message-width {
  width: 100%;
  @media (max-width: 992px) {
    width: 85%;
    margin-bottom: 10px !important;
  }
  @media (max-width: 500px) {
    width: 90% !important;
  }
}
.view-agent {
  margin-top: 5px;
  @media (max-width: 500px) {
    margin-bottom: 0px !important;
  }
}
.img-agent {
  margin-left: 15px !important;
  @media (max-width: 992px) {
    text-align: center;
    margin-left: 0px !important;
    margin-bottom: 2vw !important;
  }
  @media (max-width: 500px) {
    margin-bottom: 1vw !important;
    margin-top: 1vw !important;
  }
}
.img-marketplace-lender {
  margin-left: 15px !important;
  @media (max-width: 992px) {
    text-align: center;
    margin-left: 0px !important;
    margin-bottom: 2vw !important;
  }
  @media (max-width: 500px) {
    margin-left: 15px !important;
    margin-bottom: 1vw !important;
    margin-top: 1vw !important;
  }
}
.popover-display {
  font-size: 18px;
  // margin-top: 30px;
  @media (min-width: 1600px) {
    display: none !important;
  }
  @media (max-width: 992px) {
    display: none !important;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    margin-left: -15px !important;
  }
}
.popover-display-marketplace {
  font-size: 18px;
  // margin-top: 30px;
  @media (min-width: 1600px) and (max-width: 2080px) {
    display: none !important;
  }
  @media (max-width: 992px) {
    display: none !important;
  }
  @media (min-width: 1366px) and(max-width: 1440px) {
    margin-left: 8px;
  }
  @media (min-width: 1200px) and(max-width: 1280px) {
    margin-left: -8px;
  }
  @media (min-width: 1024px) and(max-width: 1199px) {
    margin-left: 10px;
  }
}
.popover-display-view {
  font-size: 18px;
  margin-top: 5px;
  @media (min-width: 1600px) {
    display: none !important;
  }
}

.viewprofile-display {
  font-size: 18px;
  // margin-top: 20px;
  margin-left: 5px;
  color: #99a2c3;
  @media (min-width: 993px) and (max-width: 1599px) {
    display: none !important;
  }
  @media (max-width: 992px) {
    margin-top: 10px !important;
    text-align: center;
  }
  @media (max-width: 500px) {
    margin-left: 0px !important;
  }
}
.viewprofile-display1 {
  @media (max-width: 992px) {
    display: none !important;
  }
}
.Name-agent {
  @media (min-width: 1024px) and (max-width: 1280px) {
    font-size: 14px;
  }
}
.Name-agent1 {
  @media (max-width: 992px) {
    text-align: center;
    margin-left: 0px !important;
  }
  font-size: 20px;
  // margin-top: 20px;
  color: #131419;
  font-weight: 500;
  @media (min-width: 1681px) and (max-width: 1920px) {
    margin-left: -50px;
  }
  @media (min-width: 1600px) and (max-width: 1680px) {
    margin-left: -30px;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    margin-left: 10px;
  }
}
.Email-agent {
  @media (max-width: 992px) {
    margin-left: 15px !important;
    margin-top: 0px !important;
    font-size: 15px;
  }
  @media (max-width: 500px) {
    margin-top: 5px !important;
  }
  font-size: 18px;
  // margin-top: 20px;
  margin-left: 5px;
  color: #99a2c3;
}
.Email-agent-marketplace {
  @media (max-width: 992px) {
    margin-left: 15px !important;
    margin-top: 0px !important;
  }
  font-size: 15px;
  // margin-top: 20px;
}
.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}
.divided-line-agent1 {
  display: inline-flex;
  // margin-top: 20px;
  height: 60px;
  margin-right: 2px;
  flex-wrap: wrap;
  border: 1px solid #fc572b;
  margin-left: 2px;
  justify-content: space-around;
  @media (max-width: 992px) {
    display: none;
  }
}
.divided-line-agent {
  display: inline-flex;
  // margin-top: 20px;
  height: 60px;
  margin-right: 3px;
  flex-wrap: wrap;
  border: 1px solid #fc572b;
  margin-left: 1px;
  justify-content: space-around;
  @media (max-width: 992px) {
    display: none;
  }
}
.agent-home-text {
  font-size: 15px;
  font-weight: 600;
  width: 230px;
  height: 50px;
  text-align: left;
  font-family: $primaryFontFamily;
  letter-spacing: 0px;
  color: #131419;
  font-size: 16px;
}
.card-agent {
  height: 100%;
  background-color: white;
  box-shadow: 0vw 0.2vw 0.4vw #d4d8f1fa;
  border-radius: 5px;
  margin-top: 0.45vw;
  margin-bottom: 0.45vw;
  row-gap: 0px;
  margin-left: 50px;
  margin-right: 50px;
  @media (max-width: 992px) {
    margin-bottom: 5vw !important;
  }
}
.filter-agent {
  background: white !important;
  margin-top: 13px !important;
  box-shadow: 0 0 15px 5px #f9faff;
}
.private-host {
  top: 794px;
  left: 488px;
  width: 110px;
  height: 20px;
  color: var(--unnamed-color-414555);
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 1.4px;
  color: #414555;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 10px;
}

.agent-carousel {
  .hSiCVM:disabled {
    cursor: not-allowed;
    display: none !important ;
  }
  .cetQhm {
    display: none !important;
  }
  .ixXTzo {
    margin-top: 10px !important;
  }
  .SpQeE {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 320px !important;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 286.133px !important;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 270.301px !important;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 238.663px !important;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 230.719px !important;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 223.988px !important;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 206.969px !important;
    }
  }
  .width-text-agent {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 293px;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 260px;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 245px;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 215px;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 210px;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 200px;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 185px;
    }
  }
}
.agent-portfolio-disabled {
  .ant-collapse-header {
    display: none !important;
  }
}
.marketplace-carousel {
  .hSiCVM:disabled {
    cursor: not-allowed;
    display: none !important ;
  }
  .cetQhm {
    display: none !important;
  }
  .ixXTzo {
    margin-top: 10px !important;
  }
  .SpQeE {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 320px !important;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 286.133px !important;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 270.301px !important;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 238.663px !important;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 230.719px !important;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 223.988px !important;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 206.969px !important;
    }
    //  @media (min-width: 993px) and (max-width: 1024px) {
    //   width: 175.969px !important;
    // }
  }
  .width-text-agent {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 293px;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 260px;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 245px;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 215px;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 210px;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 200px;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 185px;
    }
  }
}
.font-size-value-agent {
  @media (min-width: 1025px) and(max-width:1280px) {
    font-size: 16px;
    line-height: 1;
  }
  @media (min-width: 800px) and(max-width:1024px) {
    font-size: 14px;
  }
}
.message-icon-marketplace-margin {
  @media (min-width: 993px) {
    margin-left: 15px !important;
  }
}

.img-width-agent {
  background-color: #d4d8f1fa;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  @media (min-width: 800px) and(max-width:1024px) {
    // margin-top: 20px;
    width: 60px;
    height: 60px;
  }
}
.agent-carousel {
  .rec-arrow-left:disabled,
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    display: none !important;
  }
  .rec-arrow-left,
  .rec-arrow-right {
    margin-left: 5px !important;
    margin-right: 5px !important;
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .rec-pagination {
    display: none !important;
  }
  .rec-carousel {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .rec-item-wrapper {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 320px !important;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 286.133px !important;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 270.301px !important;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 238.663px !important;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 230.719px !important;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 223.988px !important;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      width: 206.969px !important;
    }
  }
}

.marketplace-carousel {
  .rec-arrow-left:disabled,
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    display: none !important;
    background-color: #fff;
    color: #3045d2;
  }
  .rec-arrow-left,
  .rec-arrow-right {
    margin-left: 5px !important;
    margin-right: 5px !important;
    background-color: #fff;
    color: #3045d2;
  }
  .rec-pagination {
    @media (min-width: 992px) {
      display: none !important;
    }
  }
  .rec-carousel {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  .rec-item-wrapper {
    @media (min-width: 1681px) and (max-width: 1920px) {
      width: 340px !important;
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      width: 298.133px !important;
    }
    @media (min-width: 1500px) and (max-width: 1600px) {
      width: 283.301px !important;
    }
    @media (min-width: 1401px) and (max-width: 1440px) {
      width: 253.663px !important;
    }
    @media (min-width: 1367px) and (max-width: 1400px) {
      width: 242.719px !important;
    }
    @media (min-width: 1300px) and (max-width: 1366px) {
      width: 241.988px !important;
    }
    @media (min-width: 1201px) and (max-width: 1280px) {
      width: 225.969px !important;
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      width: 178.449px;
    }
  }
}
.marketplace-homes {
  @media (min-width: 1681px) {
    top: 503px;
    left: 458px;
    width: 325px;
    height: 209px;
    border-radius: 5px;
    opacity: 1;
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    width: 285px;
    height: 200px;
  }
  @media (min-width: 1500px) and (max-width: 1600px) {
    width: 270px;
    height: 190px;
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    width: 240px;
    height: 160px;
  }
  @media (min-width: 1367px) and (max-width: 1400px) {
    width: 230px;
    height: 160px;
  }
  @media (min-width: 1300px) and (max-width: 1366px) {
    width: 230px;
    height: 160px;
  }
  @media (min-width: 1201px) and (max-width: 1280px) {
    width: 216px;
    height: 150px;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 170px;
    height: 130px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 300px;
    height: 180px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    // width: 235px;
    width: 270px;
    height: 180px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    width: 300px;
    // width: 265px;
    height: 180px;
  }
}

.agent-homes {
  @media (min-width: 1681px) {
    top: 503px;
    left: 458px;
    width: 303px;
    height: 209px;
    border-radius: 5px;
    opacity: 1;
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    width: 270px;
    height: 200px;
  }
  @media (min-width: 1500px) and (max-width: 1600px) {
    width: 255px;
    height: 190px;
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    width: 225px;
    height: 160px;
  }
  @media (min-width: 1367px) and (max-width: 1400px) {
    width: 220px;
    height: 160px;
  }
  @media (min-width: 1300px) and (max-width: 1366px) {
    width: 210px;
    height: 160px;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 195px;
    height: 150px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 300px;
    height: 180px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    width: 270px;
    height: 180px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    width: 300px;
    height: 180px;
  }
}
.agent-carousel {
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    background-color: #3045d2;
    color: #fff;
    @media (min-width: 768px) and (max-width: 1600px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
    }
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    background-color: #3045d2;
    color: #fff;
    @media (min-width: 768px) and (max-width: 1600px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
    }
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-left:hover:enabled {
    background-color: #fff;
    color: #3045d2;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #fff;
    color: #3045d2;
  }
  .rec-dot_active {
    background-color: #3045d2;
    box-shadow: 0 0 1px 3px white !important;
  }
}

.marketplace-carousel {
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    display: none !important;
    // background-color: #3045d2;
    // color: white !important;
    // @media (max-width: 992px) {
    //   height: 35px !important;
    //   width: 35px !important;
    //   min-width: 35px !important;
    //   line-height: 0px !important;
    //   font-size: 16px !important;
    // }
    // @media (min-width: 993px) {
    //   display: none !important;
    // }
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    display: none !important;
    // background-color: #3045d2;
    // color: white !important;
    // @media (max-width: 992px) {
    //   height: 35px !important;
    //   width: 35px !important;
    //   min-width: 35px !important;
    //   line-height: 0px !important;
    //   font-size: 16px !important;
    // }
    // @media (min-width: 993px) {
    //   display: none !important;
    // }
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-left:hover:enabled {
    background-color: #fff;
    color: #3045d2 !important;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #fff;
    color: #3045d2 !important;
  }
  .rec-dot_active {
    background-color: #3045d2;
    box-shadow: 0 0 1px 3px white !important;
  }
}
.agent-home-text {
  @media (min-width: 1500px) and (max-width: 1600px) {
    font-size: 14px !important;
  }
  @media (min-width: 1400px) and (max-width: 1440px) {
    font-size: 13px !important;
  }
  @media (min-width: 1300px) and (max-width: 1366px) {
    font-size: 13px !important;
  }
  @media (min-width: 1200px) and (max-width: 1280px) {
    font-size: 13px !important;
  }
}

.searchAgentHomes-titles {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-style: normal;
  font-size: 1.27vw;
  color: $black;
}
.css-1hwfws3 {
  @media (min-width: 500px) and(max-width:992px) {
    padding: 1% !important;
  }
  //agent city Search issue padding: 0.2% 0.2% 4% 1% !important;
}
// }
.FilterAgent.ant-input-affix-wrapper {
  padding: 2% !important;
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  @media (min-width: 100px) and (max-width: 499px) {
    padding: 2.3%;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    padding: 0.2% 0.2% 0.2% 1% !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 0.2% 0.2% 0.2% 1% !important;
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    padding: 2.5% !important;
  }
}

.google-city-search {
  .css-2b097c-container {
    width: 90% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
.filter-title {
  background: white !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 1.1vw;
  margin-left: 1.46vw;
  padding-right: 2vw !important;
}

.ant-select,
.check-box-property-status {
  .ant-select-selection-overflow {
    padding: 0.2vw !important;
    @media (min-width: 100px) and (max-width: 499px) {
      padding: 1% !important ;
    }
    @media (min-width: 500px) and(max-width:992px) {
      height: 5.3vw;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .homeSearch {
    .beds-size-mobile {
      margin-top: 3px !important;
      font-size: 13px !important;
    }
    .img-fit-mobile {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0px !important;
    }
    .default-image-card{
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px !important;
    }
    .header-down-text {
      font-weight: 600;
      font-size: 3vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 1vw;
    }
    .listing-heading {
      font-weight: 600;
      font-size: 3.17vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 0.55vw;
    }
    .side-bar {
      .filter-input-number {
        width: 71% !important;
      }
      .ant-input-number-input-wrap .ant-input-number-input {
        height: 0px !important;
      }

      .slider-two {
        width: 13.9vw;
        margin-top: 2vw;
        margin-left: 3.5vw;
        margin-bottom: 3vw;
      }
      .slider-three {
        margin-top: 3vw;
        margin-left: 4vw;
        width: 13.9vw;
      }
      .marginLeft {
        font-family: $primaryFontFamily;
        margin-left: 1.8vw;
      }

      .filter-padding-set {
        padding: 3vw !important;
      }
      .status-bar-level {
        margin-left: 7vw;
      }
      .baths-level-show {
        margin-top: 3vw;
      }
      .ant-checkbox-group-item {
        margin-right: 0 !important;
        margin-top: 0.36vw !important;
        margin-left: 1.56vw !important;
        margin-bottom: 0.73vw;
        font-family: $primaryFontFamily;
      }
      .ant-checkbox + span {
        font-size: 1.1vw;
        font-family: $primaryFontFamily;
        font-weight: normal;
      }
    }
    .slider-two {
      width: 13.9vw;
      margin-top: 2vw;
      margin-left: 3.5vw;
      margin-bottom: 3vw;
    }
    .slider-three {
      margin-top: 3vw;
      margin-left: 4vw;
      width: 13.9vw;
    }
    .marginLeft {
      font-family: $primaryFontFamily;
      margin-left: 1.8vw;
    }
    .ant-checkbox-group-item {
      margin-right: 0 !important;
      margin-top: 0.36vw !important;
      margin-left: 1.56vw !important;
      margin-bottom: 0.73vw;
      font-family: $primaryFontFamily;
    }
    .ant-checkbox + span {
      font-size: 1.1vw;
      font-family: $primaryFontFamily;
      font-weight: normal;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .homeSearch {
    .beds-size-mobile {
      font-size: 13px !important;
      margin-top: 3px !important;
    }
    .img-fit-mobile {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0px !important;
    }
    .default-image-card{
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px !important;
    }
    .header-down-text {
      font-weight: 600;
      font-size: 3vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 1vw;
      text-align: center;
    }
    .listing-heading {
      font-weight: 600;
      font-size: 3.17vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 0.55vw;
    }
    .side-bar {
      .filter-input-number {
        width: 71% !important;
      }
      .ant-input-number-input-wrap .ant-input-number-input {
        height: 0px !important;
      }

      .slider-two {
        width: 13.9vw;
        margin-top: 2vw;
        margin-left: 3.5vw;
        margin-bottom: 3vw;
      }
      .slider-three {
        margin-top: 3vw;
        margin-left: 4vw;
        width: 13.9vw;
      }
      .marginLeft {
        font-family: $primaryFontFamily;
        margin-left: 1.8vw;
      }
      .status-bar-level {
        margin-left: 7vw;
      }
      .filter-padding-set {
        padding: 3vw !important;
      }
      .baths-level-show {
        margin-top: 3vw;
      }
      .ant-checkbox-group-item {
        margin-right: 0 !important;
        margin-top: 0.36vw !important;
        margin-left: 1.56vw !important;
        margin-bottom: 0.73vw;
        font-family: $primaryFontFamily;
      }
      .ant-checkbox + span {
        font-size: 1.1vw;
        font-family: $primaryFontFamily;
        font-weight: normal;
      }
    }
    .slider-two {
      width: 13.9vw;
      margin-top: 2vw;
      margin-left: 3.5vw;
      margin-bottom: 3vw;
    }
    .slider-three {
      margin-top: 3vw;
      margin-left: 4vw;
      width: 13.9vw;
    }
    .marginLeft {
      font-family: $primaryFontFamily;
      margin-left: 1.8vw;
    }
    .ant-checkbox-group-item {
      margin-right: 0 !important;
      margin-top: 0.36vw !important;
      margin-left: 1.56vw !important;
      margin-bottom: 0.73vw;
      font-family: $primaryFontFamily;
    }
    .ant-checkbox + span {
      font-size: 1.1vw;
      font-family: $primaryFontFamily;
      font-weight: normal;
    }
  }
}
@media (max-width: 576px) {
  .homeSearch {
    .beds-size-mobile {
      font-size: 13px !important;
      margin-top: 3px !important;
    }
    .img-fit-mobile {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0px !important;
    }
    .default-image-card{
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px !important;
    }
    .header-down-text {
      font-weight: 600;
      font-size: 1vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 1vw;
      text-align: center;
    }
    .listing-heading {
      font-weight: 600;
      font-size: 1.17vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 0.55vw;
    }
    .side-bar {
      .filter-input-number {
        width: 71% !important;
      }
      .google-place-auto-search-location {
        height: auto !important;
        padding: 2% 3% 2% 2% !important;
      }

      .slider-two {
        width: 13.9vw;
        margin-top: 2vw;
        margin-left: 3.5vw;
        margin-bottom: 3vw;
      }
      .slider-three {
        margin-top: 3vw;
        margin-left: 4vw;
        width: 13.9vw;
      }
      .marginLeft {
        font-family: $primaryFontFamily;
        margin-left: 1.8vw;
      }

      .status-bar-level {
        margin-left: 7vw;
      }
      .filter-padding-set {
        padding: 3vw !important;
      }
      .baths-level-show {
        margin-top: 3vw;
      }
      .ant-checkbox-group-item {
        margin-right: 0 !important;
        margin-top: 0.36vw !important;
        margin-left: 1.56vw !important;
        margin-bottom: 0.73vw;
        font-family: $primaryFontFamily;
      }
      .ant-checkbox + span {
        font-size: 1.1vw;
        font-family: $primaryFontFamily;
        font-weight: normal;
      }
    }
    .slider-two {
      width: 13.9vw;
      margin-top: 2vw;
      margin-left: 3.5vw;
      margin-bottom: 3vw;
    }
    .slider-three {
      margin-top: 3vw;
      margin-left: 4vw;
      width: 13.9vw;
    }
    .marginLeft {
      font-family: $primaryFontFamily;
      margin-left: 1.8vw;
    }
    .ant-checkbox-group-item {
      margin-right: 0 !important;
      margin-top: 0.36vw !important;
      margin-left: 1.56vw !important;
      margin-bottom: 0.73vw;
      font-family: $primaryFontFamily;
    }
    .ant-checkbox + span {
      font-size: 1.1vw;
      font-family: $primaryFontFamily;
      font-weight: normal;
    }
  }
}

@media (min-width: 992) and (max-width: 1024px) {
  .homeSearch {
    .beds-size-mobile {
      font-size: 13px !important;
      margin-top: 3px !important;
    }
    .img-fit-mobile {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0px !important;
    }
    .default-image-card{
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px !important;
    }
    .header-down-text {
      font-weight: 600;
      font-size: 1vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 1vw;
    }
    .listing-heading {
      font-weight: 600;
      font-size: 12px;
      margin-top: 7px;
      height: 26px;
    }
    .side-bar {
      background: #f0f2f5;
      .ant-layout-sider {
        background: #f0f2f5 !important;
      }
      .filter-input-number {
        width: 8vw !important;
      }
      .beds-values-baths {
        font-size: 1vw;
      }
      .google-place-auto-search-location {
        height: auto !important;
        padding: 2% 3% 2% 2% !important;
      }
      .property-status-row {
        padding: 10px !important;
      }
      .slider-two {
        width: 13.9vw;
        margin-top: 2vw;
        margin-left: 3.5vw;
        margin-bottom: 3vw;
      }
      .slider-three {
        margin-top: 3vw;
        margin-left: 4vw;
        width: 13.9vw;
      }
      .marginLeft {
        font-family: $primaryFontFamily;
        margin-left: 1.8vw;
      }
      .status-bar-level {
        margin-left: 0vw;
      }
      .ant-checkbox-group-item {
        margin-right: 0 !important;
        margin-top: 0.36vw !important;
        margin-left: 1.56vw !important;
        margin-bottom: 0.73vw;
        font-family: $primaryFontFamily;
      }
      .ant-checkbox + span {
        font-size: 1.1vw;
        font-family: $primaryFontFamily;
        font-weight: normal;
      }
    }
  }
}
.home-image-style {
  @media (min-width: 210px) and(max-width: 576px) {
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
}
.mobile-buyer-card-padding {
  padding: 10px !important;
  padding-left: 15px !important;
  @media (max-width: 767px) {
    padding: 10px !important;
  }
}

@media (min-width: 210px) and(max-width: 576px) {
  .homeSearch {
    .profile-image-mobile {
      padding: 0%;
      margin-left: 0px !important;
    }
    .img-fit-mobile {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 0px !important;
    }
    .default-image-card{
      object-fit: cover;
      width: 100%;
      height: 280px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px !important;
    }
    .header-down-text {
      font-weight: 600;
      font-size: 4vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 1vw;
      text-align: center;
    }
    .listing-heading {
      font-weight: 600;
      font-size: 4.17vw;
      color: #000;
      margin-top: 1vw;
      margin-bottom: 0.55vw;
    }
    .side-bar {
      background: #f0f2f5;
      .ant-layout-sider {
        background: #f0f2f5 !important;
      }
      .filter-input-number {
        width: 71% !important;
      }

      .google-place-auto-search-location {
        height: auto !important;
        padding: 2% 3% 2% 2% !important;
      }
      .property-status-row {
        padding-bottom: 10px !important;
      }
      .slider-two {
        width: 13.9vw;
        margin-top: 2vw;
        margin-left: 3.5vw;
        margin-bottom: 3vw;
      }
      .slider-three {
        margin-top: 3vw;
        margin-left: 4vw;
        width: 13.9vw;
      }
      .marginLeft {
        font-family: $primaryFontFamily;
        margin-left: 1.8vw;
      }
      .status-bar-level {
        margin-left: 0vw;
      }
      .margin-beds {
        font-family: $primaryFontFamily;
        margin-top: 1.09vw;
        margin-left: 2vw !important;
        font-size: 1.5vw;
      }
      .ant-checkbox-group-item {
        margin-right: 0 !important;
        margin-top: 0.36vw !important;
        margin-left: 1.56vw !important;
        margin-bottom: 0.73vw;
        font-family: $primaryFontFamily;
      }
      .ant-checkbox + span {
        font-size: 1.1vw;
        font-family: $primaryFontFamily;
        font-weight: normal;
      }
    }
  }
}
.home-card-space-bw {
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0vw 0.2vw 0.4vw #d4d8f1fa;
  border-radius: 5px;
  margin-top: 0.45vw;
  margin-bottom: 0.45vw;

  @media (min-width: 100px) and(max-width:499px) {
    margin-top: 2.45vw;
    margin-bottom: 2.45vw;
  }
  @media (min-width: 500px) and(max-width:992px) {
    margin-top: 1.45vw;
    margin-bottom: 1.45vw;
  }
}

.mobile-view-padding {
  @media (min-width: 100px) and(max-width:767px) {
    margin-top: 2vw;
  }
}
.mobile-view-padding-down {
  margin-bottom: 1rem !important;
  @media (min-width: 100px) and(max-width:767px) {
    margin-bottom: 2vw !important;
  }
}
.home-card-date {
  margin-left: 10px;
  margin-top: 0.5vw;
  @media (min-width: 100px) and(max-width:992px) {
    float: right;
    margin-top: 0 !important;
  }
}

.slider-one {
  padding-bottom: 1vw;
  @media (min-width: 100px) and (max-width: 499px) {
    padding-bottom: 3vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    padding-bottom: 2vw;
  }
}

.second-row-title {
  background: white !important;
  font-family: $primaryFontFamily;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 1em;
  font-size: 1.1vw;

  @media (min-width: 100px) and(max-width:499px) {
    font-size: 4.1vw;
  }
  @media (min-width: 500px) and(max-width:992px) {
    font-size: 2.1vw;
  }
}
.min-price-label {
  padding-right: 2vw !important;
  font-size: 0.9vw !important;
  font-family: $primaryFontFamily;
  @media (max-width: 499px) {
    font-size: 4vw !important;
    padding-right: 4vw !important;
  }
  @media (min-width: 500px) and(max-width: 767px) {
    font-size: 1.9vw !important;
    padding-right: 4vw !important;
  }
  @media (min-width: 768px) and(max-width: 992px) {
    font-size: 1.9vw !important;
    padding-right: 4vw !important;
  }
  @media (min-width: 993px) and(max-width: 1024px) {
    font-size: 1.1vw !important;
    padding-right: 1vw !important;
  }
}
.margin-header {
  padding-right: 1.5vw !important;
  font-size: 0.9vw !important;
  font-family: $primaryFontFamily;
  margin-top: -12px;
  @media (min-width: 100px) and(max-width:499px) {
    font-size: 4vw !important;
    padding-right: 4vw !important;
    margin-top: -7px;
  }
  @media (min-width: 500px) and(max-width: 992px) {
    font-size: 1.9vw !important;
    padding-right: 4vw !important;
  }
  @media (min-width: 993px) and(max-width: 1024px) {
    font-size: 1.1vw !important;
    padding-right: 1vw !important;
  }
}
.margin {
  font-family: $primaryFontFamily;
  font-size: 1.5vw;
}
.margin-text {
  font-family: $primaryFontFamily;
  font-size: 0.73vw;
  margin: 1vw;
  margin-top: 5px;
  margin-right: 2.5vw;
  margin-left: 2.5vw;
  @media (min-width: 993px) and(max-width: 1024px) {
    margin-right: 2vw;
    margin-left: 2vw;
    margin-top: 3px;
  }
  @media (min-width: 500px) and(max-width:992px) {
    margin-right: 25vw;
    margin-left: 25vw;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  @media (max-width: 499px) {
    margin-right: 14vw;
    margin-left: 14vw;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

.min-area-width {
  width: 11vw;
  @media (min-width: 100px) and(max-width:499px) {
    width: 49vw;
    height: 8.3vw;
  }
  @media (min-width: 500px) and(max-width:767px) {
    width: 62vw;
  }
  @media (min-width: 768px) and(max-width:992px) {
    width: 62vw;
  }
}
.ant-input-group,
.ant-input {
  @media (min-width: 500px) and(max-width:992px) {
    height: 5.3vw;
  }
}
.min-price-width {
  width: 11vw;
  @media (min-width: 100px) and(max-width:499px) {
    width: 49vw;
    height: 8.3vw;
  }
  @media (min-width: 500px) and(max-width:767px) {
    width: 62vw;
    height: 5.3vw;
  }
  @media (min-width: 768px) and(max-width:992px) {
    width: 62vw;
    height: 5.3vw;
  }
}
.beds-margin {
  font-family: $primaryFontFamily;
  font-size: 1.5vw;
  margin-left: 0.6vw !important;
  @media (min-width: 993px) and(max-width: 1024px) {
    margin-left: 0.7vw !important;
  }
  @media (min-width: 768px) and(max-width:992px) {
    margin-left: 1.2vw !important;
  }
  @media (min-width: 500px) and(max-width:767px) {
    margin-left: 1.2vw !important;
  }
  @media (min-width: 100px) and(max-width:499px) {
    margin-left: 2.2vw !important;
  }
}

.check-box-property-status {
  width: 90% !important;
  margin-right: 5% !important;
  margin-left: 5% !important;
}

.beds-PlusCircle {
  font-size: 1.5vw !important;
  @media (min-width: 100px) and(max-width:767px) {
    font-size: 5vw !important;
  }
  @media (min-width: 500px) and(max-width:767px) {
    font-size: 3vw !important;
  }
  @media (min-width: 768px) and(max-width:992px) {
    font-size: 3vw !important;
  }
}
.beds-minusCircle {
  font-size: 1.5vw !important;
  @media (min-width: 100px) and(max-width:499px) {
    font-size: 5vw !important;
  }
  @media (min-width: 500px) and(max-width:767px) {
    font-size: 3.5vw !important;
  }
  @media (min-width: 768px) and(max-width:992px) {
    font-size: 3.5vw !important;
  }
}
.beds-values-baths {
  font-size: 1vw;
  @media (min-width: 100px) and(max-width:767px) {
    font-size: 4vw !important;
  }
  @media (min-width: 500px) and(max-width:767px) {
    font-size: 2.5vw !important;
  }
  @media (min-width: 768px) and(max-width:992px) {
    font-size: 2.5vw !important;
  }
}

.beds-beaths-row {
  @media (min-width: 768px) and(max-width:1024px) {
    margin-bottom: 5px;
  }
}

.show-on-mobile {
  @media (min-width: 1024px) {
    display: none !important;
  }
}

.show-on-desktop {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.search-results-container {
  @media (min-width: 1024px) {
    float: right !important;
  }
  @media (max-width: 1023px) {
    float: left !important;
  }
}

.bath-left-margin {
  display: flex !important;
  @media (min-width: 501px) {
    display: none !important;
  }
}
.desktop-display {
  margin-left: 20px;
  display: flex !important;
  @media (max-width: 500px) {
    margin-left: 0px !important;
    display: none !important;
  }
}
