@import "./common.scss";

.login-container {
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    display: none;
  }
}

.ant-scrolling-effect {
  overflow-y: scroll !important;
}

.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px !important;
  height: 100%;
  background: #fff;
  border-left: 0px !important;
  border-radius: 0px !important;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}

.login-container .image1 {
  width: 100%;
  height: 100%;
}
.login-container .content {
  position: absolute;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: $white;
  width: 60%;
  bottom: 10%;
  left: 5%;
  font-size: 20px;
  text-align: left;
  padding: 3vw 5.12vw 1.46vw 1.46vw;
  height: 200px;
}

.login-container .logo {
  position: absolute;
  top: 5%;
  left: 5%;
}
.register {
  background-color: #3045d2;
  text-align: right;
  .register-text {
    color: $white;
    font-size: 15px;
    font-weight: 600;
    float: right;
    font-family: $primaryFontFamily;
    margin: 15px;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
}
.checking {
  .ant-btn {
    line-height: 0px;
    padding: 0.2vw 1.09vw;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):first-child {
    position: relative;
    color: white;
    background-color: #f75b47;
    border: 1px solid #f75b47;
    height: 42px;
    border-radius: 5px;
  }
  .ant-radio-button-wrapper:first-child:last-child {
    height: 42px;
    border-radius: 5px;
  }
}
.register-form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  font-size: 1.7vw;
}

.col-Register-form {
  margin-left: 25%;
}
.col-Register-form-modal {
  margin-left: 15%;
}
.login-form {
  height: auto;
  align-items: center;
  color: $grey11;
  font-family: $primaryFontFamily;
  font-style: normal;

  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 0.7vw;
    @media (min-width: 993px) and(max-width: 1024px) {
      font-size: 10px;
    }
    @media (max-width: 767px) {
      font-size: 2.7vw;
    }
  }
  .ant-input {
    font-size: 0.9vw;
    height: 40px;
    border-radius: 5px;
  }
  .ant-input-number {
    font-size: 0.9vw;
    height: 50px;
    border-radius: 5px;
    padding-top: 4px;
    padding-right: 11px;
    padding-bottom: 4px;
    padding-left: 11px;
    border: 1px solid #d9d9d9;
    border-color: #d9d9d9 !important;

    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 1.5vw;
    }
    @media (min-width: 100px) and (max-width: 767px) {
      font-size: 2.9vw;
    }
  }
  .form-icon-size {
    @media (min-width: 475px) and (max-width: 991px) {
      font-size: 1.02vw;
    }
  }
  .ant-form-item .ant-input-number:hover {
    border-color: #1890ff !important;
  }

  .ant-form-item {
    @media (min-width: 500px) and (max-width: 767px) {
      margin-bottom: 1.5vw;
    }
    @media (min-width: 765px) and (max-width: 991px) {
      margin-bottom: 1.8vw;
    }
  }
  .remove-bottom-space {
    margin-bottom: 0vw;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    width: 1vw;
    height: 1vw;
    @media (min-width: 100px) and (max-width: 499px) {
      width: 5vw;
      height: 4vw;
    }
    @media (min-width: 500px) and (max-width: 767px) {
      width: 3.5vw;
      height: 2.8vw;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 2.5vw;
      height: 2vw;
    }
  }
  .ant-checkbox-inner::after {
    width: 0.3vw;
    height: 0.5vw;
    @media (min-width: 100px) and (max-width: 499px) {
      width: 1.3vw;
      height: 2.5vw;
    }
    @media (min-width: 500px) and (max-width: 767px) {
      width: 1vw;
      height: 2vw;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 0.8vw;
      height: 1.5vw;
    }
  }
}
.iamMarginTop {
  margin-top: 4%;
}
.login-form-forgot {
  float: right;
  color: $grey11;
  font-size: 1.1vw;
}
.login-form-forgot {
  @media (min-width: 300px) and (max-width: 440px) {
    font-size: 2.1vw !important;
  }
}
.login-form-center {
  font-family: $primaryFontFamily;
  text-align: center;
}
.login-form-center-weight {
  font-weight: bold;
  font-size: 1.9vw;
}
.register-form-center-weight {
  font-weight: bold;
  font-size: 1.9vw;
  @media (min-width: 100px) and(max-width: 499px) {
    font-size: 5.9vw;
  }
  @media (min-width: 500px) and(max-width: 767px) {
    font-size: 3.5vw;
  }
}

.login-form-center-color {
  color: $grey11;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  // background-color: #f75b47;
  // border: 5px solid #f75b47;
  // color: #fff;
  height: 45px;
  border-radius: 5px;
  width: 100%;
}
.login-form-button {
  @media (min-width: 900px) and (max-width: 1024px) {
    height: 40px;
  }
  @media (min-width: 600px) and (max-width: 768px) {
    height: 35px;
  }
  @media (min-width: 300px) and (max-width: 500px) {
    height: 30px;
  }
}

.login-form-button:hover,
.login-form-button:focus {
  color: #fff;
  // background: #f75b47;
  // border-color: #f75b47;
}
.image-border {
  padding: 0.36;
  width: 1.83vw;
  border-radius: 1.09vw;
  border: 1px solid rgba(65, 69, 85, 0.204);
}

.terms {
  font-size: 0.9vw;
}

.modal-form-input {
  .padding {
    padding: 1vw;
  }

  .ant-form-item {
    margin-bottom: 0px;
    padding: 0vw 0.73vw;
  }
  .ant-form-item-control-input {
    width: max-content;
  }
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  background-color: #fff !important;
}

.user-text-size {
  margin-top: 5px;
  font-size: 1vw !important;
}

.login-form,
.terms {
  @media (min-width: 100px) and(max-width:499px) {
    font-size: 2.9vw;
  }
  @media (min-width: 500px) and(max-width:768px) {
    font-size: 1.9vw;
  }
  @media (min-width: 768px) and(max-width:1024px) {
    font-size: 1.5vw;
  }

  .ant-input {
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 1.5vw;
      // height: 40px !important;
      border-radius: 5px !important ;
    }
    @media (min-width: 100px) and (max-width: 499px) {
      font-size: 2.8vw;
    }
    @media (min-width: 500px) and (max-width: 767px) {
      font-size: 2.8vw;
    }
  }
}

.col-Register-form {
  @media (min-width: 730px) and (max-width: 800px) {
    margin-left: 15%;
  }
  @media (min-width: 533px) and (max-width: 684px) {
    margin-left: 9%;
  }
  @media (min-width: 441px) and (max-width: 532px) {
    margin-left: 10%;
  }
  @media (min-width: 415px) and (max-width: 440px) {
    margin-left: 5%;
  }
  @media (min-width: 400px) and (max-width: 414px) {
    margin-left: 5%;
  }
  @media (min-width: 300px) and (max-width: 399px) {
    margin-left: 10%;
  }
}
.col-Login-form {
  @media (min-width: 1024px) and (max-width: 1920px) {
    margin-left: 10%;
  }
  @media (min-width: 400px) and (max-width: 424px) {
    margin-left: 0% !important;
  }
}
.checking {
  @media (min-width: 100px) and(max-width:499px) {
    .ant-radio-button-wrapper:first-child:last-child {
      height: 30px;
      border-radius: 5px;
    }
  }
  @media (min-width: 500px) and(max-width:767px) {
    .ant-radio-button-wrapper:first-child:last-child {
      height: 30px;
      border-radius: 5px;
      width: 125px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .ant-radio-button-wrapper:first-child:last-child {
      height: 35px;
      border-radius: 5px;
    }
  }
}
.user-text-size {
  @media (min-width: 100px) and (max-width: 499px) {
    margin-top: -2px !important;
    font-size: 2.8vw !important;
    text-align: center;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-top: -2px !important;
    font-size: 1.8vw !important;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: -1px !important;
    font-size: 1.5vw !important;
    text-align: center;
  }
}
.password-criteria {
  margin-top: -7px;
  margin-bottom: 0px;
  @media (min-width: 768px) and (max-width: 1366px) {
    margin-top: -20px;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    margin-top: -20px;
  }
}
.row-margin-top {
  margin-top: 3vw;
  margin-bottom: 0;
  @media (min-width: 100px) and (max-width: 1366px) {
    margin-top: 1vw;
  }
}

.register-iam {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.advertisement-margin-login {
  @media (max-width: 767px) {
    display: none;
  }
}
.login-height {
  @media (max-width: 767px) {
    height: 88.5vh !important;
    margin-top: 3vw;
  }
}

.register_MarginTop {
  @media (max-width: 767px) {
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
}

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper {
  background-color: #fff;
  border-color: #d9d9d9 !important;
}
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  border-color: #1890ff !important;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #1890ff !important;
  box-shadow: 0 0 0 1px #b7dcff !important;
}

.register-confirm {
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
}
.mobile-view-display {
  @media (min-width: 768px) {
    display: none;
  }
  text-align: center !important;
}
.logo-header-login {
  margin-top: 3vw !important;
  width: 50%;
  height: 100%;
  // @media (min-width: 100px) and (max-width: 499px) {
  //   width: 100px !important;
  // }
  // @media (min-width: 500px) and (max-width: 767px) {
  //   width: 35%;
  // }
}
.close-btn {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 22px;
}
.homecard-drawer {
  .ant-drawer-close {
    color: #fff !important;
  }
}

.login-margin-top {
  @media (min-width: 992px) {
    margin-top: 3vw !important;
  }
}
.height-image {
  object-fit: cover;
  height: 100vh !important;
  @media (min-width: 993px) and (max-width: 1024px) {
    height: 118vh !important;
  }
}
.criteria {
  @media (max-width: 768px) {
    margin-top: 10px !important;
  }
}
.setting_icon {
  font-size: 20px;
  margin-right: 10px;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
}

.form-input-reCaptch {
  height: 45px !important;
  border-radius: 5px !important;
  .ant-input-group-addon {
    padding: 0px !important;
  }
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  width: 96%;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.4px;
  @media (max-width: 767px) {
    width: 70%;
  }
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
  font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 #354c8c;
  background: linear-gradient(#4c69ba, #3b55a0);
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
