@import "./common.scss";

.font-family-style {
  font-family: $primaryFontFamily;
  font-style: normal;
}

.font-normal-size {
  font-weight: normal;
}

.font-medium-size {
  font-weight: 600;
}

.font-large-size {
  font-weight: bold;
}

.text-size {
  font-size: 1.02vw;
}

.text-size2 {
  font-size: 1.1vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 3vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    font-size: 4vw !important;
    margin-bottom: 10px !important;
  }
}
.text-size2-homes {
  font-size: 1.1vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.5vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    font-size: 4vw !important;
    margin-bottom: 10px !important;
  }
}
.text-size3 {
  font-size: 1.1vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.2vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.5vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    font-size: 3vw !important;
  }
}
.text-size-name {
  font-size: 1.1vw;
  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.17vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.3vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 1.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 3vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 3.5vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 200px) and (max-width: 499px) {
    font-size: 5vw !important;
    margin-bottom: 10px !important;
  }
}
.padding-text-name {
  @media (min-width: 993px) and(max-width:2040px) {
    padding: 3%;
  }
  @media (max-width: 992px) {
    padding: 1%;
  }
}
.text-size1 {
  font-size: 0.93vw;
}
.color-grey {
  color: $grey11;
}
.anticon {
  vertical-align: 0em;
}
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.display-flex-evenly {
  display: flex;
  justify-content: space-evenly;
}

.display-flex-between {
  display: flex;
  justify-content: space-between;
}

.display-flex-around {
  display: flex;
  text-align: left;
  justify-content: space-around;
}
.beds-homes-evenly {
  display: flex;
  justify-content: space-between;
}
.display-flex-end {
  display: flex;
  justify-content: space-evenly;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.image-display {
  display: cover;
  width: 100%;
}

.thumbnail-slider-wrap {
  @media (max-width: 500px) {
    margin-top: 0px !important;
  }
  margin-top: 15px;
  height: 75%;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70%;
  height: 50%;
  @media (max-width: 767px) {
    width: 85% !important;
    height: 100px !important;
  }
}

.slick-slide-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  @media (min-width: 1024px) {
    height: 500px;
  }
}

// .slick-slider {
//   @media (max-width: 400px) {
//     height: 210px;
//   }
// }
// .slider-wrapper {
//   @media (max-width: 400px) {
//     height: 315px;
//   }
// }
.slick-prev {
  color: #777777;
  z-index: 1;
  left: 5px;
  height: 35px;
  width: 30px;
}
.slick-next {
  color: #777777;
  outline: none;
  background: transparent;
  right: 15px;
  height: 35px;
  width: 30px;
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}
.down {
  .slick-prev:before {
    color: black;
  }
  .slick-next:before {
    color: black;
  }
}

.object-Fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ant-btn {
  line-height: 0.5715;
}

.thumbnail-div-fixes {
  display: -webkit-box;
  margin-right: 4vw;
  margin-top: 0.73vw;
}
