@import "common.scss";

.OpenHeader {
  .ant-layout-header {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border-radius: 10px;
    opacity: 1;
    @media (min-width: 768px) and (max-width: 998px) {
      height: 8.3vw;
      padding: 8px;
    }
    @media (min-width: 998px) and (max-width: 1200px) {
      height: 7.3vw;
      padding: 10px;
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
      height: 6.3vw;
      padding: 8px;
    }
    @media (min-width: 1400px) {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      // border-radius: 10px;
      height: 6.3vw;
      padding: 22px 22px 22px 22px !important;
      opacity: 1;
    }
  }

  .header-titles {
    padding-inline-end: 3.2vw;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
  }
  .font-header {
    font-size: 0.9vw;
    // font-size: 16px;
    padding: 0.73vw;
    font-family: $primaryFontFamily;
    font-weight: 500;
  }
  .button {
    .ant-btn {
      line-height: 0.5715;
    }
  }
}
.userInfo-header {
  width: 100%;
  height: auto;
  padding: 9.0045px 0;
  background: white;
  border-top: 1px solid #d3dde4;
  z-index: 1;
}
.grid__inline {
  margin-left: 15px;
  margin: 0 auto 0 15%;
  max-width: 1250px;
  display: flex;
  @media (max-width: 1023px) {
    display: none;
  }
}
.grid__inline_non_login {
  margin: 0 auto 0 20%;
  max-width: 1250px;
  display: flex;
  @media (max-width: 1023px) {
    display: none;
  }
}
.mobile_View {
  @media (min-width: 1023px) {
    display: none;
  }
}
.userInfo-header .agent-photo {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  margin-right: 1em;
  border-radius: 50%;
}
.userInfo-header .agent-name {
  font-size: 21.328px;
  margin-right: 1em;
}
.userInfo-header .agent-phone {
  font-size: 21.328px;
  font-weight: bold;
  color: #e74c3c;
  margin-right: 1em;
}

.img_header_styling {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  margin-left: 5px;
  margin-right: 5px;
}
