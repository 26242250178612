@import "common.scss";

.container {
  background: var(--unnamed-color-f9faff) 0% 0% no-repeat padding-box;
  background: #f9faff 0% 0% no-repeat padding-box;
  opacity: 1;

  .card-container {
    color: rgb(128, 123, 123);
  }
}
.home-page-search {
  font-size: 1.02vw !important;
  font-weight: 600 !important;
  font-family: $primaryFontFamily !important;
  letter-spacing: 0px;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 992px) {
    font-size: 50px;
  }
}
.container-text-detail {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-size: 50px;
  font-weight: bolder;
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;
}
.container-index {
  opacity: 1;
  position: absolute;
  z-index: 1;
  background-color: $white;
  display: flex;
  justify-content: space-between;
}
.property {
  opacity: 1;
  position: absolute;
  z-index: 1;
  // background-color: $white;
  display: flex;
  justify-content: space-between;
}

.property-text {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.property-text1 {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.property-icon {
  background: var(--unnamed-color-3045d2) 0% 0% no-repeat padding-box;
  background: #3045d2 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #3045d215;
  border-radius: 5px;
  width: 3vw;
  height: 3vw;
}
.property-search-text {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: normal;
  color: #99a2c3;
}

.open_card_carousel {
  height: 380px !important;
}

.close_card_carousel {
  height: 100px !important;
}

.carsole {
  .rec-carousel-item {
    width: auto;
    margin-right: 0vw;
    height: auto;
  }
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
  }
  .rec-arrow-left:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-dot_active {
    background-color: #f75b47;
    box-shadow: 0 0 1px 3px white !important;
  }
  .rec-pagination {
    display: none !important;
  }
}
.home-carousel {
  .rec-carousel-item {
    width: auto;
    margin-right: 0vw;
    height: auto;
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-left:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-dot_active {
    background-color: #f75b47;
    box-shadow: 0 0 1px 3px white !important;
  }
}
.portfolio-carousel {
  .rec-carousel-item {
    width: auto;
    margin-right: 0vw;
    height: auto;
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    // @media (max-width: 992px) {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    line-height: 0px !important;
    font-size: 16px !important;
    // }
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    // @media (max-width: 992px) {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    line-height: 0px !important;
    font-size: 16px !important;
    // }
  }
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
    // @media (max-width: 992px) {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    line-height: 0px !important;
    font-size: 16px !important;
    // }
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #f75b47;
    color: #fff;
    // @media (max-width: 992px) {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    line-height: 0px !important;
    font-size: 16px !important;
    // }
  }
  .rec-arrow-left:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #fff;
    color: #f75b47;
  }
  .rec-dot_active {
    background-color: #f75b47;
    box-shadow: 0 0 1px 3px white !important;
    display: none !important;
  }
  .rec-dot {
    background-color: #f75b47;
    box-shadow: 0 0 1px 3px white !important;
    display: none !important;
  }
}
.profilecard-head {
  width: 100%;
  height: 60%;
  position: relative;
  text-align: center;
  cursor: pointer !important;
  @media (min-width: 1281px) and (max-width: 1480px) {
    height: 55% !important;
  }
  @media (max-width: 1280px) {
    height: 50% !important;
  }
  .profilecard-day {
    position: absolute;
    color: #3045d2;
    top: 6%;
    left: 3%;
    padding: 0.2vw;
    font-size: 0.73vw;
    background: white;
    box-shadow: 0px 1px 0px 2px #3045d229;
    border-radius: 5px;
  }
}
.profilecard-body {
  width: 100%;
  height: 40%;
  color: black;
  font-family: $primaryFontFamily;
  font-style: normal;
  margin-left: 1.6vw;
  margin-top: 1.3vw;
  cursor: pointer !important;
  padding-right: 4vw;
  .private-post {
    text-transform: uppercase;
    color: red;
    letter-spacing: 1.6px;
    font-size: 0.73vw;
  }
}

@media (min-width: 700px) {
  .container-text-detail {
    margin-left: 90px;
    margin-top: 100px;
    margin-bottom: 15px;
    font-size: 23px;
  }
  .container-index {
    margin-left: 90px;
    width: 100%;
    padding: 15px;
  }
  .multiselect {
    width: 100%;
    word-wrap: break-word;

    .dropdown-container {
      height: 2.7vw;
      line-height: initial;
      border: none;
    }
  }

  .property-text {
    // font-size: $fontSizeH9;
    // font-style: normal;
    // margin-top: 10px;
    font-size: $fontSizeH9 !important;
    font-style: 0 !important;
    margin-top: 0 !important;
  }
  .property-text1 {
    // font-size: $fontSizeH9;
    // margin-top: 10px;
    // margin-right: 95px;
    font-size: $fontSizeH9 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
  }
  .property {
    // margin-left:70px;
    margin-top: 80px;
    margin-left: 90px;
    width: 100%;
    padding: 15px;
  }

  .property-search-text {
    font-size: $fontSizeH8;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container-text-detail {
    margin-top: 135px;
    margin-left: 90px;
    margin-bottom: 20px;
    font-size: 33px;
  }
  .container-index {
    margin-left: 90px;
    width: 100%;
    padding: 15px;
  }
  .property-text {
    font-size: $fontSizeH24;
    margin-top: 10px;
  }
  .property {
    margin-left: 90px;
    margin-top: 100px;
    width: 100%;
    padding: 15px;
  }
  .property-text1 {
    font-size: $fontSizeH9;
    margin-top: 10px;
    margin-right: 115px;
  }

  .property-search-text {
    font-size: $fontSizeH8;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1400px) {
  .container-text-detail {
    margin-top: 190px;
    margin-left: 120px;
    margin-bottom: 40px;
    font-size: 40px;
  }
  .container-index {
    margin-left: 120px;
    width: 100%;
    padding: 10px;
  }
  .property-text {
    font-size: $fontSizeH2;
    margin-top: 10px;
  }
  .property {
    margin-left: 120px;
    margin-top: 120px;
    width: 100%;
    padding: 10px;
  }
  .property-text1 {
    font-size: $fontSizeH24;
    margin-top: 10px;
    margin-right: 120px;
  }
  .property-search-text {
    font-size: $fontSizeH5;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .container-text-detail {
    margin-top: 150px;
    margin-left: 95px;
    margin-bottom: 40px;
    font-size: 45px;
  }
  .container-index {
    margin-left: 95px;
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
  }
  .property {
    margin-top: 165px;
    margin-left: 120px;
    width: 100%;
    padding-top: 20px;
    padding-left: 10px;
  }
  .property-text {
    font-size: 20px !important;
    margin-top: 0 !important;
  }
  .property-text1 {
    font-size: 20px !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
  }
  .property-search-text {
    font-size: $fontSizeH3;
  }
}

.layout-width {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.asset-logo {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.img-width {
  @media (max-width: 349px) {
    height: 185vw !important;
  }
  @media (min-width: 350px) and(max-width: 385px) {
    height: 170vw !important;
  }
  @media (min-width: 386px) and(max-width: 400px) {
    height: 150vw !important;
  }
  @media (min-width: 401px) and(max-width: 500px) {
    height: 145vw !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    height: 100vw !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    height: 68vw !important;
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    height: 52vw !important;
  }
}
.home-Btn-primary {
  width: 100%;
  height: 100%;
  background: #3045d2;
  @media (max-width: 500px) {
    height: 10vw !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    height: 6vw !important;
  }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 15px !important;
  @media (max-width: 500px) {
    font-size: 10px !important;
  }
}
.home-margin-btn {
  @media (max-width: 500px) {
    margin-top: 1vw !important;
    margin-bottom: 2vw !important;
  }
  @media (min-width: 501px) and(max-width: 767px) {
    margin-top: 1vw !important;
    margin-bottom: 1vw !important;
  }
}

.sale-heading {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 30px;
  @media (max-width: 500px) {
    margin-top: 3vw !important;
    margin-bottom: 1vw !important;
    font-size: 20px !important;
    text-align: center;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 3vw !important;
    margin-bottom: 3vw !important;
    font-size: 28px !important;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    text-align: center;
    margin-top: 3vw !important;
    margin-bottom: 3vw !important;
  }
}

.sale-content {
  color: var(--unnamed-color-414555);
  text-align: left;
  font-family: $primaryFontFamily;
  font-size: 1.16vw;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  color: #414555;
  opacity: 1;
  margin-right: 4vw;
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
  @media (min-width: 501px) and(max-width: 767px) {
    font-size: 18px !important;
  }
  @media (min-width: 768px) and(max-width: 992px) {
    font-size: 18px !important;
  }
}

.content {
  text-align: center;
  font-family: $primaryFontFamily;
  font-size: $fontSizeH3;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

.side-title {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH9;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
  margin-left: 10px;
  margin-right: 50px;
  @media (max-width: 500px) {
    margin-right: 0px !important;
    margin-left: 30px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-right: 0px !important;
    margin-left: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    margin-left: 25px !important;
  }
}
.side-title1 {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH9;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
  margin-left: 10px;
  margin-right: 50px;
  @media (max-width: 500px) {
    margin-right: 0px !important;
    margin-left: 30px !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-right: 0px !important;
    margin-left: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    margin-left: 25px !important;
  }
}
.side-title2 {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH9;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
  margin-left: 10px;
  margin-right: 50px;
  @media (max-width: 500px) {
    margin-right: 0px !important;
    margin-left: 30px !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-right: 0px !important;
    margin-left: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    margin-left: 25px !important;
  }
}

.side-content {
  text-align: left;
  margin-left: 10px;
  font-family: $primaryFontFamily;
  font-size: $fontSizeH5;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
  margin-top: 5px;
  @media (max-width: 500px) {
    margin-left: 30px !important;
  }
  @media (min-width: 501px) and(max-width:767px) {
    margin-top: 10px;
    margin-left: 15px !important;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    margin-left: 25px !important;
  }
}
.background-color {
  background: #f75b4714;
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  @media (max-width: 500px) {
    padding: 15px !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    padding-top: 20px !important;
    padding: 15px !important;
  }
}
.homes-img-width {
  @media (max-width: 992px) {
    width: 24px !important;
  }
}
.card-box {
  opacity: 1;
  .colors {
    color: #f75b47;
    :hover {
      color: $pink;
    }
  }
  :hover {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-3045d2);
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid #3045d2;
    border-radius: 5px;
    opacity: 1;
  }
}
.private-color {
  font-size: 18px;
  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}
.private-color-budget {
  font-size: 18px;
  margin-bottom: 0px !important;
  @media (min-width: 1024px) and (max-width: 1440px) {
    font-size: 17px;
  }
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
}
.private-color-towns {
  font-size: 14px;
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
}
.New-listing-margin {
  @media (min-width: 992px) {
    margin-top: 60px;
  }
}
.card-title-homes {
  font-size: 18px;
}
.card-description-homes {
  @media (min-width: 501px) {
    margin-top: -15px !important;
  }
}

.buyers-text {
  margin-top: 9vw;
  font-family: $primaryFontFamily;
  font-size: $fontSizeH32;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: $black;
  @media (min-width: 100px) and(max-width: 499px) {
    text-align: center;
    font-size: 20px !important;
  }
  @media (min-width: 500px) and(max-width:992px) {
    margin-top: 5vw !important;
  }
}
.title {
  text-align: center;
  font-family: $primaryFontFamily;
  font-size: $fontSizeH32;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: $black;
  @media (min-width: 100px) and(max-width: 499px) {
    text-align: center;
    font-size: 20px !important;
  }
}
.row-gap-home {
  @media (min-width: 100px) and(max-width: 499px) {
    row-gap: 15px !important;
  }
}
.buyers-content {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH5;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  color: $black;
}
.contentTitle {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH30;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 60px;
  @media (max-width: 500px) {
    margin-top: 3vw !important;
    margin-bottom: 1vw !important;
    font-size: 20px !important;
    text-align: center;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 3vw !important;
    margin-bottom: 3vw !important;
    font-size: 28px !important;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    text-align: center;
    margin-top: 3vw !important;
    margin-bottom: 3vw !important;
  }
}
.padding-top {
  padding: 1%;
  @media (max-width: 500px) {
    padding: 5vw 0 5vw 0vw !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    padding: 0 0 3vw 0vw !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 2%;
  }
}
.padding-row-home {
  padding: 1%;
  @media (max-width: 500px) {
    padding: 0 !important;
  }
}
.contentStyle {
  width: 100%;
  font-family: $primaryFontFamily;
  text-align: left;
}

.height {
  @media (min-width: 1700px) and (max-width: 2000px) {
    height: 90vh;
  }
}

.openHeader-Address {
  // .css-1hwfws3{
  //  padding: 10px;
  //  }
  .css-yk16xz-control {
    border: #f7f8f9;
    background-color: #f7f8f9;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: #f7f8f9 !important;
  }
  .css-tlfecz-indicatorContainer {
    color: #f7f8f9 !important;
  }
  .css-1pahdxg-control:hover {
    border-color: #3045d2 !important;
    border-radius: 5px;
  }
}
.go2646822163:focus-within {
  border-color: #3045d2 !important;
  box-shadow: 0 0 0 1px #3045d2 !important;
  border-radius: 5px;
  box-sizing: border-box;
  border-width: 1px !important;
  border-style: solid;
}

.home-text {
  color: white;
  font-size: 2.6vw;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 4.6vw !important;
    margin-bottom: 6vw !important;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 4.6vw !important;
    margin-bottom: 2vw !important;
  }
}
.home-text-check {
  font-size: 21px !important;
  color: white;
  text-shadow: #575856 2px 2px 5px !important;
  @media (max-width: 500px) {
    font-size: 14px !important;
  }
}
.check-icon-home {
  color: #fff;
  font-size: 24px !important;
}
.home-margin-row {
  margin-bottom: 10px !important;
  // @media (min-width: 1681px) and (max-width: 2080px) {
  //   margin-bottom: 35px !important;
  // }
  // @media (min-width: 1366px) and (max-width: 1440px) {
  //   margin-bottom: 35px !important;
  // }
  @media (max-width: 992px) {
    margin-bottom: 5px !important;
  }
}
// .home-margin-row1 {
//   margin-bottom: 10px !important;
//   @media (max-width: 992px) {
//     margin-bottom: 5px !important;
//   }
// }
// .home-margin-row2 {
//   @media (max-width: 992px) {
//     margin-bottom: 5px !important;
//   }
// }
.img-bg {
  background-image: url("../images/asset.png");
  background-size: cover;
  background-position: center;
}

.img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    transform: none;
    top: 5% !important;
    left: 5% !important;
    right: 5% !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    transform: none;
    top: 5% !important;
    left: 5% !important;
    right: 5% !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    transform: none;
    top: 10% !important;
    left: 10% !important;
    right: 10% !important;
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    transform: none;
    top: 10% !important;
    left: 15% !important;
    right: 15% !important;
  }
}

.side-row-top {
  margin-top: 50px;
  @media (max-width: 992px) {
    margin-top: 3vw !important;
  }
}
.carousel-height {
  height: 30vw !important;
  cursor: pointer;
  background: white;
  margin-bottom: 1vw !important;
  margin-top: 4vw !important;
  border: none !important;
  @media (max-width: 500px) {
    height: 290px !important;
    width: 340px !important;
    margin: 0px !important;
  }
  @media (min-width: 501px) and (max-width: 992px) {
    height: 400px !important;
    width: 645px !important;
    margin: 0px !important;
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    height: 35vw !important;
    margin: 0px !important;
  }
}
.btn-homes-carousel {
  box-shadow: 0 0 2px 0px #333;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 50px;
  cursor: pointer;
  outline: none;
  margin-left: 20vw;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-homes-carousel1 {
  box-shadow: 0 0 2px 0px #333;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 50px;
  cursor: pointer;
  outline: none;
  margin-left: 40vw;
  margin-top: 10px;
  margin-bottom: 10px;
}
.beds-home {
  margin-top: 10px;
  margin-bottom: 10px;
  @media (min-width: 1024px) and (max-width: 1280px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media (max-width: 500px) {
    margin-top: 0px !important;
    margin-bottom: 5px;
  }
}
.carsole {
  .rec-arrow-left:disabled,
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .rec-arrow-left,
  .rec-arrow-right {
    @media (max-width: 767px) {
      display: none !important;
    }
  }
}
.padding-card-whyjoin {
  padding: 50px 50px 50px 50px !important;
  @media (max-width: 767px) {
    padding: 15px !important;
  }
}
.padding-card1-whyjoin,
.padding-card1-whyjoin-image-card {
  @media (min-width: 1681px) {
    height: 29vw;
    padding: 30px !important;
  }
  @media (min-width: 1441px) and (max-width: 1680px) {
    height: 32.5vw;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    height: 39.5vw;
  }
  @media (min-width: 1024px) and(max-width: 1279px) {
    height: 50.5vw;
  }
  @media (min-width: 993px) and(max-width: 1023px) {
    padding: 15px !important;
  }
  @media (max-width: 992px) {
    padding: 10px !important;
  }
  @media (max-width: 500px) {
    height: auto !important;
  }
  @media (min-width: 501px) and (max-width: 991px) {
    height: 62vw;
  }
}
.padding-card1-whyjoin-image-card {
  @media (max-width: 500px) {
    height: auto !important;
  }
  @media (min-width: 501px) and (max-width: 991px) {
    height: auto !important;
  }
}
.padding-card1-whyjoinUs {
  padding: 30px !important;
  @media (min-width: 993px) and(max-width: 1024px) {
    padding: 15px !important;
  }
  @media (max-width: 992px) {
    padding: 10px !important;
  }
}
.card-description {
  @media (max-width: 992px) {
    margin-top: 15px;
  }
}
.card-description1 {
  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
}
.margin-body-agent {
  @media (min-width: 1024px) {
    margin-left: 2.5% !important;
    margin-right: 0.34% !important;
  }
}
.margin-body-agent-right {
  @media (min-width: 1024px) {
    margin-right: 2.5% !important;
    margin-left: 0.34% !important;
  }
}
.margin-left-agent {
  @media (min-width: 1024px) {
    margin-left: 2% !important;
  }
}
.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container_virtual {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container_virtual::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container_virtual iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.customPanelStyle {
  background: #fff;
  border-radius: 4;
  margin: 20px;
  border: 0;
  overflow: hidden;
  @media (max-width: 500px) {
    margin: 0px !important;
  }
}
.width-whyjoinus {
  width: 100vw !important;
}

.ant-btn-primary {
  color: #fff !important;
  background: #3045d2 !important;
  border-color: #3045d2 !important;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #3045d2 !important;
  border-color: #3045d2 !important;
}
.card-minHeight {
  min-height: 12vw;
  @media (min-width: 1367px) and(max-width: 1440px) {
    min-height: 15vw;
  }
  @media (min-width: 1281px) and(max-width: 1366px) {
    min-height: 17.5vw;
  }
  @media (min-width: 1200px) and(max-width: 1280px) {
    min-height: 21vw;
  }
  @media (min-width: 1024px) and(max-width: 1199px) {
    min-height: 28vw;
  }
  @media (min-width: 768px) and(max-width: 992px) {
    min-height: 22vw;
  }
}

.ant-modal-close-x {
  color: white !important;
}
.modal-width {
  .ant-modal-close-x {
    color: black !important;
  }
}
.modal-black-close {
  .ant-modal-close-x {
    color: black !important;
  }
}
.card-BuyHome {
  overflow: inherit;
  background: #f9faff;
  @media (min-width: 768px) {
    padding: 30px;
    height: 400px;
  }
}

.button_landingScreen {
  position: absolute;
  margin-top: 40px;
  // transform: translate(-50%, -50%);
  //   left: 50%;
}

.button_data {
  background: #3045d2 !important;
  color: #fff !important;
  font-weight: bold;
  margin-top: 10px;
}
.ant-input-number-input-wrap {
  height: 100% !important;
}

.ant-input-number-input {
  height: 100% !important;
}
.ant-input-number_numberFormat {
  height: 30px !important;
  border-radius: 5px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9 !important;
}

.ant-input-number_numberFormat .focus-visible {
  border: 1px solid #d9d9d9 !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number_numberFormat:focus,
.ant-input-number_numberFormat-focused {
  border-color: #d9d9d9 !important;
  box-shadow: 0 0 0 1px #d9d9d9 !important;
  border-right-width: 1px;
  outline: 0;
}

.ant-input-number_numberFormat:hover {
  border-color: #d9d9d9 !important;
  border-right-width: 1px;
}

.filter_search .ant-collapse-header {
  width: 100% !important;
}
