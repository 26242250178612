@import "./common.scss";

.site-layout-content {
  padding: 24px;
  background: #fff;
}
.ql-container.ql-snow {
  height: 10vw !important;
}
.google-form-search {
  .css-1hwfws3 {
    padding: 0.2% 0.2% 3% 1% !important;
  }
}
.google-form-search-service-area {
  .css-1hwfws3 {
    padding: 0.2% 0.2% 1.5% 1% !important;
  }
}
.header-menu-titles {
  text-align: left;
  font: normal normal medium 16px/28px Poppins;
  font-size: 16px !important ;
  font-weight: medium !important;
  font-family: $primaryFontFamily !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.header-menu-titles {
  @media (min-width: 1025px) and (max-width: 1366px) {
    font-size: 14px !important ;
  }
  @media (min-width: 900px) and (max-width: 1024px) {
    font-size: 12px !important ;
  }
  @media (min-width: 768px) and (max-width: 800px) {
    font-size: 10px !important ;
  }
}

.label-titles {
  text-align: left;
  font-size: 15px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin-bottom: 5px !important;
}

.label-description {
  text-align: left;
  font-size: 16px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 500 !important;
  letter-spacing: 0px;
  color: #666;
  opacity: 1;
  margin-bottom: 1vw !important;
}
.btn-updated {
  background-color: #0bc43c !important;
  color: #ffffff !important;
}
@media (min-width: 1441px) and (max-width: 3000px) {
  .input-number-myprofile {
    width: 31.5vw !important;
  }
}
@media (max-width: 1440px) {
  .input-number-myprofile {
    width: 31vw !important;
  }
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px !important;
  height: 100%;
  background: #fff;
  border-left: 0px !important;
  border-radius: 0px !important;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
