/* COLORS */

$white:#fff;
$layout: #f0f2f5;
$grey:#363D47;
$grey1:#000;
$grey2:#c3c5d1;
$grey3:#767c8e;
$grey4:#DFE0E4;
$grey5:#A6ACB7;
$grey6:#DEE1F0;
$grey7:#939598;
$grey8:#4F5765;
$grey9:#252C34;
$grey10: #717782;
$grey11: #99A2C3;
$primaryColor:#1F61DC;
$black: #000;
$lightblack: #152542;
$red: #D71921;
$pink:#ffc0bc;
$orange: #f87c00;
$redGradient: #D71921;
$greenGradient: #47A067;
$greyGradient: #586881;
$blueGradient:#3071FC;
$lightred: #FD69614D;


/* FONT-FAMILY */
$primaryFontFamily:'Poppins', sans-serif;


// :'Raleway', sans-serif;
/*FONT SIZES*/
$fontSizeH1: 26px;
$fontSizeH2: 22px;
$fontSizeH3: 18px;
$fontSizeH4: 17px;
$fontSizeH5: 16px;
$fontSizeH6: 14px;
$fontSizeH7: 13px;
$fontSizeH8: 12px;
$fontSizeH9:  20px;
$fontSizeH10: 8px;
$fontSizeH11: 6px;
$fontSizeH12: 10px;
$fontSizeH13: 9px;
$fontSizeH14: 11px;
$fontSizeH15: 15px;
$fontSizeH24: 24px;
$fontSizeH28: 28px;
$fontSizeH30: 30px;
$fontSizeH32: 32px;
$fontSizeH40: 40px;
