@import "common.scss";

.ant-layout-footer {
  margin-right: 0px;
}
.footer {
  .ant-row {
    .footer-background {
      color: #fff;
      min-height: 50%;
      height: auto;
      padding: 5%;
      // background-image: url(../images/building.png);
      background-size: cover;
      align-items: center;
      display: block;
      text-align: center;
      background-color: "red";

      .footer-text {
        color: "var(--unnamed-color-ffffff)";
        width: 60%;
        font-family: $primaryFontFamily;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
      }
      .footer-button {
        text-align: center;
        position: relative;
        font-family: $primaryFontFamily;
        line-height: 0.5717;
        width: 10.5vw;
        height: 2.2vw;
        border-radius: 5px;
        @media (min-width: 100px) and(max-width:499px) {
          width: 20.5vw;
          height: 7.5vw;
        }
        @media (min-width: 500px) and(max-width:767px) {
          width: 12.5vw;
          height: 4.5vw;
        }
        @media (min-width: 768px) and(max-width:1024px) {
          width: 12.5vw;
          height: 3.5vw;
        }
      }
    }
  }
  .margin-Top {
    margin-top: 0.4vw;
    margin-bottom: 0.4vw;
    font-family: $primaryFontFamily;
    font-size: 1.02vw;
    @media (min-width: 1366px) and (max-width: 1600px) {
      font-size: 1vw;
    }
    @media (min-width: 1280px) and (max-width: 1365px) {
      font-size: 1.1vw;
    }
    @media (min-width: 1024px) and (max-width: 1279px) {
      font-size: 1.4vw !important;
    }
    @media (min-width: 700px) and (max-width: 900px) {
      font-size: 1.7vw;
    }
    @media (min-width: 541px) and (max-width: 699px) {
      font-size: 1.9vw;
    }
    @media (min-width: 400px) and (max-width: 540px) {
      font-size: 2.1vw;
    }
    @media (min-width: 200px) and (max-width: 399px) {
      font-size: 2.4vw !important;
    }
  }
  .footer-text {
    font-size: 2.5vw;
  }
  .footer-buttonText {
    font-family: $primaryFontFamily;
    font-size: 1.02vw;
    @media (min-width: 100px) and(max-width:499px) {
      font-size: 4vw;
    }
    @media (min-width: 500px) and(max-width:768px) {
      font-size: 1.42vw;
    }
  }
}

.icons-padding {
  margin: 0;
  padding: 0.55vw 25%;
  @media (min-width: 100px) and(max-width:499px) {
    padding: 0% 10% 0% 10% !important;
  }
}
.icon-size-color {
  font-size: 24px !important;
  color: #fff;

  @media (min-width: 100px) and(max-width:499px) {
    font-size: 14px !important;
  }
}

.footer-about {
  margin: 0;
  padding: 0% 20% 0% 20%;
  @media (min-width: 100px) and(max-width:499px) {
    padding: 0% 5% 0% 5% !important;
  }
  @media (min-width: 500px) and(max-width:767px) {
    padding: 0% 10% 0% 10% !important;
  }
}
.footer-logo {
  height: 100%;
  width: 14%;
  margin-top: -0.7vw;
  @media (min-width: 100px) and(max-width:499px) {
    width: 35%;
  }
  @media (min-width: 500px) and(max-width:1024px) {
    width: 20%;
  }
}

.copyRight {
  font-size: 14px;
  color: #fff;
  margin-top: 0.55vw;
  font-family: $primaryFontFamily;
  @media (min-width: 100px) and(max-width:499px) {
    font-size: 10px !important;
  }
}
