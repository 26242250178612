@import "./common.scss";

.search-bar {
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-input-group.ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 10px 10px 0;
  }
  .ant-input-group > .ant-input-group-addon:last-child {
    background: #2537ac 0% 0% no-repeat padding-box;
  }
  .ant-input-search-button {
    background: #2537ac 0% 0% no-repeat padding-box;
  }
}

.header-layout {
  padding: 0px;
}
.header-background {
  background: #3045d2 0% 0% no-repeat padding-box;
}
.selector {
  .ant-select-selector {
    border: 0px;
    color: white;
  }
  .ant-select-arrow {
    color: white;
    font-size: 0.65vw;
  }
}
.ant-select-item {
  font-size: 1.02vw;
}

.select-outlet {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
    font-size: 1.02vw;
  }
}
.header-margin {
  margin-top: 13px;
  @media (min-width: 900px) and (max-width: 1024px) {
    margin-top: 8px;
  }
  @media (min-width: 1025px) and (max-width: 1366px) {
    margin-top: 8px;
  }
  @media (min-width: 770px) and (max-width: 800px) {
    margin-top: 5px;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-left: 5vw;
    margin-right: 10vw;
    margin-top: 5vw;
  }
  @media (min-width: 300px) and (max-width: 499px) {
    margin-right: 6vw;
    margin-left: 1vw;
    margin-top: 20px;
  }
}
.notify-col {
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 6vw;
    margin-left: 10vw;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-top: 6vw;
    margin-left: 10vw;
  }
  @media (min-width: 300px) and (max-width: 499px) {
    margin-top: 20px;
    margin-left: 6vw;
  }
}
.heart-col {
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 6vw !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-top: 6vw !important;
  }
  @media (min-width: 300px) and (max-width: 499px) {
    margin-top: 20px !important;
  }
}
.header-margin-desk {
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.logo-login {
  @media (min-width: 1601px) and (max-width: 2040px) {
    margin-top: -15px !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  @media (min-width: 1367px) and (max-width: 1600px) {
    margin-top: -13px !important;
  }
  @media (min-width: 1280px) and (max-width: 1366px) {
    margin-top: 2px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    margin-top: -3px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    margin-top: -6px !important;
  }
  @media (min-width: 700px) and (max-width: 800px) {
    margin-top: -3px !important;
  }
}
@media (max-width: 1024px) {
  .header-profile {
    .ant-avatar-circle {
      top: -3px !important;
    }
  }
}
.header-row {
  @media (min-width: 700px) and(max-width:800px) {
    margin-top: 5px;
  }
  @media (min-width: 600px) and(max-width:699px) {
    margin-top: 5px;
  }
}
.logout-header {
  @media (min-width: 1600px) {
    font-size: 24px;
  }
}
// .logo-header {
//   display: cover;
//   width: 100%;
//   height: 100px;
//   object-fit: cover;
//   @media (min-width: 1600px) {
//     height: 130px;
//   }
//   @media (min-width: 993px) and(max-width:1024px) {
//     width: 100%;
//     height: 70px;
//   }
//   @media (min-width: 100px) and(max-width:499px) {
//     width: 65%;
//     height: 80px;
//   }
//   @media (min-width: 500px) and(max-width:767px) {
//     width: 50%;
//   }
//   @media (min-width: 768px) and(max-width:992px) {
//     width: 55%;
//   }
// }
// img {
//   max-width: 100%;
//   height: auto;
// }
.logo-header {
  max-width: 100%;
  height: auto;
  display: flex;
  // margin: auto;
  background: transparent !important;
  padding-top: 10px;
  padding-left: 10px;
}
.img_response {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;
  overflow: hidden;
}
.heart-header {
  font-size: 24px !important;
  cursor: pointer !important;

  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 20px !important;
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    font-size: 20px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: -5px !important;
    font-size: 25px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-top: -10px !important;
    font-size: 25px !important;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 28px !important;
  }
}

.notify-header {
  @media (min-width: 1025px) and (max-width: 1280px) {
    margin-top: -2px !important;
    width: 19px !important;
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    margin-top: -2px !important;
    width: 19px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: -10px !important;
    width: 24px !important;
  }
  // @media (min-width: 768px) and (max-width: 800px) {
  //   width: 16px !important;
  // }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-top: -10px !important;
    width: 24px !important;
  }
}

.notification {
  background: none !important;
  box-shadow: none;
  padding: 0px;
}
.notification::after {
  font-family: $primaryFontFamily;
  top: 0%;
  font-size: 0.9vw;
  font-weight: 700;
  padding: 0.21vw 0.51vw;
}
.notify {
  line-height: 5vw;
  .bs-popover-auto[x-placement^="bottom"],
  .bs-popover-bottom {
    width: max-content;
  }
  .notification-info-panel .nodata {
    font-size: 1.2vw;
  }
}

.header-profile {
  opacity: 0.9;
  border: none;
  cursor: pointer !important;
  .ant-avatar-circle {
    width: 3vw;
    height: 3.5vw;
    top: -9px;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    color: white;
    font-size: 0.99vw;
    margin-bottom: 0px !important;
    @media (min-width: 600px) and (max-width: 800px) {
      font-size: 1.4vw;
    }
  }

  .ant-card-meta-description {
    color: white;
    font-size: 0.73vw;
    @media (min-width: 600px) and (max-width: 800px) {
      font-size: 1.1vw;
    }
  }
  .ant-card-body {
    padding: 10px 10px 0px 10px !important;
  }
}
.ant-card-meta-title {
  margin-bottom: 0px !important;
}
.ant-card-meta-avatar {
  @media (min-width: 600px) and (max-width: 800px) {
    padding-right: 10px;
  }
}
.color-white {
  color: white;
}

.header-text {
  font-size: 0.9vw;
}

.divider-vertical {
  background: white;
  height: 3.5vw;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.4;
}
.text-about {
  color: #54595f;
  text-align: justify;
  font-size: 16px;
  font-family: sans-serif;
}
.img-about {
  box-shadow: 0px 0px 10px 0px;
  border: 12px solid rgb(28, 45, 65);

  @media (min-width: 1301px) and (max-width: 1440px) {
    height: 680px !important;
  }
  @media (min-width: 770px) and (max-width: 1300px) {
    height: 500px !important;
  }
  @media (min-width: 768px) and (max-width: 769px) {
    height: 580px;
  }
}
.margin-aboutus {
  @media (max-width: 992px) {
    margin-top: 1em !important;
  }
}

.header-sidebar {
  @media (min-width: 992.5px) {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 992px) {
  .display-flex-end {
    display: none;
  }
  .header-logo-class {
    display: none;
  }
  .favourties-header {
    display: none;
  }
  .items_Center_show {
    display: none !important;
  }
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

/*=========================== MENU ===========================*/

/* Header fixed and background colors */
.header {
  position: sticky;
  top: 0px;
  max-height: 100%;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
}
/* UL element Navigation Main Menu */
.navbar-collapse {
  max-height: 100%;
  padding-left: 0;
  padding-right: 0;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar {
  margin-bottom: 0;
  border-radius: 0px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.header .navbar {
  margin-bottom: 0;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  position: relative;
  // min-height: 80px;
}
.navbar-nav {
  padding-right: 30px;
}
/* Menu LI items and children */
.navbar-nav {
  list-style: none;
  margin-top: 55px;
  padding-right: 20px;
}
li.nav-item {
  position: relative;
  font-size: 1em;
  float: left;
  height: 45px;
  padding-left: 30px; /* space between menu items */
}
a.nav-item-child {
  font-size: 1em;
  font-family: "Oswald", sans-serif !important;
  color: #fff;
  line-height: 25px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.navbar-nav > li.nav-item > a:hover {
  margin-bottom: 10px;
  border-bottom: 2px solid #3045d2;
  padding-bottom: 3px;
  color: rgb(253, 126, 20);
}
header .nav-item-child:focus,
.header .nav-item-child:hover {
  background: inherit;
}
/* MAX-WIDTH 767px */
@media (max-width: 767px) {
  .header .menu-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .header .navbar > .container,
  .header .navbar > .container > .nav-collapse {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .header .navbar > .container {
    width: auto;
  }
}
/* Toggle  */
.header .nav-item-hover:after,
.link:after,
.work .work-overlay:before {
  content: " ";
}
.header .navbar-toggle {
  width: 45px;
  height: 35px;
  border: none;
  margin: 35px 0;
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 31px;
  margin-right: 35px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.header .navbar-toggle .toggle-icon {
  position: relative;
  width: 21px;
  height: 1px;
  display: inline-block;
  background: #fff;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-toggle .toggle-icon:after,
.header .navbar-toggle .toggle-icon:before {
  position: absolute;
  left: 0;
  background: #fff;
  content: " ";
}
.header .navbar-toggle .toggle-icon:before {
  width: 10px;
  height: 1px;
  bottom: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-toggle .toggle-icon:after {
  width: 16px;
  height: 1px;
  top: -5px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-toggle:hover .toggle-icon {
  background: #ae952e;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-toggle:hover .toggle-icon:after,
.header .navbar-toggle:hover .toggle-icon:before {
  width: 21px;
  height: 1px;
  background: #ae952e;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .header .logo-img-main,
.header .nav-item.active .nav-item-hover:after,
.header .nav-item:hover .nav-item-hover:after {
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.header .navbar-toggle:hover .toggle-icon.is-clicked {
  background: rgba(81, 87, 105, 0);
}

/* MIN-WIDTH 768px */
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
/* MAX-WIDTH 991px */
@media (max-width: 991px) {
  .header .navbar-nav {
    margin: 0;
    float: none;
  }
  .header .navbar-nav .nav-item {
    float: none;
  }
  .header .menu-container:after,
  .header .menu-container:before {
    content: " ";
    display: table;
  }
  .header .menu-container:after {
    clear: both;
  }
  .header .logo .logo-img-main {
    display: none;
  }
  .header .logo .logo-img-active {
    display: inline-block;
  }
  .header .nav-item-child {
    line-height: 1.4;
    padding: 12px 12px 12px 15px;
  }
  .header .nav-item-hover:after {
    position: absolute;
    top: 19px;
  }
  /*======= TOGGLE MENU ======*/
  #toggle,
  #toggle + label {
    display: inherit;
  }
  #toggle {
    width: 45px;
    height: 40px;
    opacity: 0;
    border: none;
    margin: 35px 0;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1000;
    float: right;
    padding: 9px 10px;
    margin-top: 31px;
    margin-right: 35px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
  }
  .menu-mobile {
    text-align: center;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  }
  #toggle:checked ~ .menu-mobile {
    height: auto;
  }
  #toggle + label {
    position: absolute;
    top: 27px;
    right: 25px;
    border: none;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 5px;
    @media (min-width: 100px) and (max-width: 499px) {
      top: 0px;
      margin-top: 16px;
    }
    @media (min-width: 500px) and (max-width: 767px) {
      top: 0px;
      margin-top: 4.5vw;
    }
  }
  #toggle + label:after {
    content: "\2630";
    font-size: 1.7em;
  }
  #toggle:hover + label {
    color: #ae952e;
  }
  #toggle:checked ~ .message {
    height: 150px;
  }
  #toggle:checked + label:after {
    content: "\2573";
    font-size: 1.4em;
  }
}
/* MIN-WIDTH 992px */
@media (min-width: 992px) {
  .header .navbar-nav-right {
    float: right;
  }
  .page-on-scroll .header .navbar {
    background: #3045d2;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .page-on-scroll .header .logo-img-active,
  .page-on-scroll .header .logo-img-main {
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .page-on-scroll .header .nav-item-child {
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
}

.avatar-img-header {
  @media (min-width: 768px) and (max-width: 992px) {
    margin-left: 10vw;
    margin-top: -10px;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    margin-left: 10vw;
    margin-top: -10px;
  }
  @media (min-width: 300px) and (max-width: 499px) {
    margin-left: 6vw;
    // margin-top: -10px;
  }
}

.header-img-btn {
  width: 50px;
  height: 50px;
  top: 5px;
  @media (min-width: 1281px) and(max-width:1600px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 1100px) and(max-width:1280px) {
    width: 35px;
    height: 35px;
  }
  @media (min-width: 1024px) and(max-width:1099px) {
    width: 30px;
    height: 30px;
    top: 2px;
  }
  @media (min-width: 768px) and(max-width:1025px) {
    width: 32px;
    height: 32px;
    top: -1px;
    margin-left: -3px;
  }
}

// .bottom-nav-item.active {
//   color: #fc572b !important;
// }
.bottom-nav {
  background: rgba(0, 0, 0, 0);
}
// .bottom-nav-item {
//   color: white !important;
// }
.bottom-nav-item--title {
  cursor: pointer;
}

.ant-card-hoverable {
  @media (max-width: 767px) {
    border-color: transparent !important;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
  }
}

.profile-height {
  margin-top: 18px;
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 41px;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    margin-top: 45px;
  }
}
.notification-height {
  width: 360px;
  @media (max-width: 500px) {
    width: 295px !important;
  }
  .ant-tabs-tabpane {
    height: 300px;
    overflow: auto;
    @media (max-width: 500px) {
      height: 200px !important;
    }
  }
}

.background-transparent .ant-drawer-content {
  background: rgba(0, 0, 0, 0.05) !important;
}

.items_Center_show {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
}
.items_Center {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
